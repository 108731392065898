import type { App } from '@endaoment-frontend/config';
import { config, featureFlags } from '@endaoment-frontend/config';

type RouteOptions = {
  hash?: string;
  useFullUrl?: boolean;
};

export const makeRoute =
  <Args>(routeFn: (args: Args) => string, app: App = 'app') =>
  (argsWithOptions?: Args & RouteOptions): string => {
    const { hash, useFullUrl, ...args } = { useFullUrl: false, ...argsWithOptions };
    const suffix = hash ? `#${hash}` : '';

    // If we're on the same app, don't prefix the route with the app's base URL
    // This is to be used on vercel deployments, to tell the app to which routes are its own
    if (featureFlags.appName === app && !useFullUrl) return `${routeFn(args as Args)}${suffix}`;

    const prefix = config.baseUrls[app];
    return `${prefix}${routeFn(args as Args)}${suffix}`;
  };
