import { makeRoute } from './makeRoute';

const marketingRoute = (args: Parameters<typeof makeRoute>[0]) => makeRoute(args, 'marketing');

export const home = marketingRoute(() => `/`);
export const about = marketingRoute(() => `/about`);
export const learn = marketingRoute(() => `/learn`);
export const donors = marketingRoute(() => `/donors`);
export const orgs = marketingRoute(() => `/orgs`);
export const advisors = marketingRoute(() => `/advisors`);
export const otc = marketingRoute(() => `/otc`);
export const donationCalculator = marketingRoute(() => `/calculators/donation`);
export const ndao = marketingRoute(() => `/ndao`);
export const impact = marketingRoute(() => `/impact`);
export const _404 = marketingRoute(() => `/404`);
