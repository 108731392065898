import {
  createParser,
  createSerializer,
  parseAsBoolean,
  parseAsFloat,
  parseAsInteger,
  parseAsJson,
  parseAsString,
  parseAsStringEnum,
} from 'nuqs';
import SuperJSON from 'superjson';
import type { z } from 'zod';

import type { SupportedBroker } from '@endaoment-frontend/types';
import {
  bigIntSchema,
  brokerageInfoFormSchema,
  donationRecipientSchema,
  evmTokenSchema,
  grantInstructionInputSchema,
  stockLotSchema,
  stockTickerSchema,
  uuidSchema,
} from '@endaoment-frontend/types';

const createParserForSchema = <T extends z.ZodType>(schema: T) =>
  createParser<z.infer<T>>({
    parse: v => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return schema.parse(SuperJSON.parse(v));
      } catch {
        return null;
      }
    },
    serialize: v => SuperJSON.stringify(v),
  });
const parseAsUuid = createParserForSchema(uuidSchema);
const parseAsNullableUuid = createParserForSchema(uuidSchema.nullish());
const parseAsBigInt = createParserForSchema(bigIntSchema);

export const targetAllocationModalParsers = {
  isTargetAllocationModalOpen: parseAsBoolean.withDefault(false).withOptions({
    clearOnDefault: true,
  }),
  targetAllocationFundId: parseAsUuid,
} as const;

export const donationWizardParsers = {
  isDonationWizardOpen: parseAsBoolean.withDefault(false).withOptions({
    clearOnDefault: true,
  }),
  dwMode: parseAsStringEnum(['brokerage-donation', 'credit-donation', 'erc-donation', 'grant', 'otc-donation']),
  dwRecipient: parseAsJson(v => donationRecipientSchema.parse(v)),
  dwAmount: parseAsBigInt.withDefault(0n).withOptions({
    clearOnDefault: true,
  }),
  creditPledgeAmount: parseAsInteger.withDefault(0).withOptions({
    clearOnDefault: true,
  }),
  dwIncludeTaxReceipt: parseAsBoolean.withDefault(false).withOptions({
    clearOnDefault: true,
  }),
  ercToken: parseAsJson(v => evmTokenSchema.parse(v)),
  grantOriginId: parseAsUuid,
  grantInstructions: parseAsJson(v => grantInstructionInputSchema.omit({ recommendationId: true }).parse(v)),
  otcTokenId: parseAsInteger,
  otcDonationTransactionHash: parseAsString.withDefault('').withOptions({
    clearOnDefault: true,
  }),
  brokerageTicker: parseAsJson(v => stockTickerSchema.parse(v)),
  brokerageShares: parseAsFloat.withDefault(0).withOptions({
    clearOnDefault: true,
  }),
  brokerageLots: parseAsJson(v => stockLotSchema.array().parse(v))
    .withDefault([])
    .withOptions({
      clearOnDefault: true,
    }),
  brokerageBroker: parseAsJson(v => brokerageInfoFormSchema.parse(v))
    .withDefault({
      // Even though typescript isn't happy about this, undefined is the most optimal default value for the brokerage field
      // it will be forced to be a valid value in the form
      brokerage: undefined as unknown as SupportedBroker,
      brokerageAccountNumber: '',
      brokerageContactName: '',
      brokerageEmail: '',
      brokeragePhone: '',
    })
    .withOptions({
      clearOnDefault: true,
    }),
  isRebalanceRequested: parseAsBoolean.withDefault(true).withOptions({
    clearOnDefault: true,
  }),
  recommendationId: parseAsUuid,
  requestScheduledLiquidation: parseAsBoolean.withDefault(false).withOptions({ clearOnDefault: true }),
} as const;

export const fundWizardParsers = {
  isFundWizardOpen: parseAsBoolean.withDefault(false).withOptions({
    clearOnDefault: true,
  }),
  fundWizardMode: parseAsStringEnum(['create', 'migrate']),
  destinationFundId: parseAsNullableUuid.withOptions({ clearOnDefault: true }),
  newFundName: parseAsString.withDefault('').withOptions({ clearOnDefault: true }),
  // Set default to -1 to ensure that the type is always a number and be able to tell that it's not set
  newFundChainId: parseAsInteger.withDefault(-1).withOptions({ clearOnDefault: true }),
  referralCode: parseAsString.withDefault('').withOptions({ clearOnDefault: true }),
  referralSource: parseAsString.withDefault('').withOptions({ clearOnDefault: true }),
  migrateAmount: parseAsBigInt.withDefault(0n).withOptions({ clearOnDefault: true }),
} as const;

export const portfolioWizardParsers = {
  isPortfolioWizardOpen: parseAsBoolean.withDefault(false).withOptions({
    clearOnDefault: true,
  }),
  portfolioWizardFundId: parseAsUuid,
  portfolioWizardPortfolioId: parseAsUuid,
  portfolioWizardAmount: parseAsBigInt.withDefault(0n).withOptions({
    clearOnDefault: true,
  }),
  portfolioWizardIsDeposit: parseAsBoolean.withDefault(true).withOptions({
    clearOnDefault: true,
  }),
} as const;

export const assembleSearchParamsForWizards = createSerializer({
  ...donationWizardParsers,
  ...fundWizardParsers,
  ...portfolioWizardParsers,
  ...targetAllocationModalParsers,
});
export type SearchParamsUsedByWizards = Parameters<typeof assembleSearchParamsForWizards>[1];
